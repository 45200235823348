import { cloneDeep } from 'lodash-es'
import { encryptByBase64 } from 'framework/utils/cipher'
import { useMultipleTabStore } from '@admin/store/modules/multipleTab'
import dayjs from 'dayjs'
import { router } from '@admin/router'
import { useTimeoutFn } from '@vueuse/core'

export const useHandleIframe = () => {
  // 对iframe_url 进行Base64
  const conversionUrl = (menu: any) => {
    const cloneDeepData = cloneDeep(menu)
    const url = cloneDeepData.iframe_url
    const iframe = encryptByBase64(url)
    cloneDeepData.name = cloneDeepData.title
    cloneDeepData.path = `/iframe?url=${encodeURIComponent(iframe)}&menuId=${cloneDeepData.id}`

    return cloneDeepData
  }
  // iframe 路由跳转
  const iframeRouteTo = (params) => {
    if (!params || !params.path) return
    // 构造参数
    const newOpen = {
      id: router.currentRoute.value.query?.menuId || 0,
      iframe_url: params.path,
      title: params.title || '',
      meta: { title: `${params?.title || ''}`, cache: params.cache ? true : false },
    }
    // 转换Url
    const menu = conversionUrl(newOpen)
    // 跳转
    let JumpUrl = menu.meta?.title
      ? `${menu.path}&title=${encodeURIComponent(menu.meta?.title)}`
      : `${menu.path}`
    JumpUrl = params.cache ? `${JumpUrl}&cache=true` : JumpUrl
    router.push(JumpUrl)
  }
  // iframe 关闭 中台tab标签
  const iframeRouteBack = (params = {}) => {
    const tabStore = useMultipleTabStore()
    const tab = tabStore.getCurrentTab(router.currentRoute.value)
    const isSpecifyOpen = params.path ? false : true
    tabStore.closeTab(tab, router, isSpecifyOpen)
    tabStore.delTabCache(tab)
    if (params && params.path) {
      const newOpen = {
        id: router.currentRoute.value.query?.menuId || 0,
        iframe_url: params.path,
        title: '',
        meta: { title: '' },
      }
      // 转换Url
      const menu = conversionUrl(newOpen)
      useTimeoutFn(() => {
        router.push(menu.path)
      }, 0)
    }
  }
  // iframe设置title
  const iframeSetTabTitle = (title = `${dayjs().unix()}`) => {
    const tabStore = useMultipleTabStore()
    const findTab: any = tabStore.tabList.find(
      (item) => item.fullPath === router.currentRoute.value.fullPath,
    )
    if (!findTab) return
    tabStore.setTabTitle(decodeURIComponent(title), findTab)
  }
  return {
    conversionUrl,
    iframeRouteTo,
    iframeRouteBack,
    iframeSetTabTitle,
  }
}
